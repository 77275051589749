import React from "react";
//import logo from './img/logo-updated.jpg';
import Banner from "./Main_Banner";
import './header.css';
import './Media.css';
function Header() {
    return (
        <frameElement>
            <div className="header-container">
                <div className="header-col-left"></div>
                <div className="header-col-right"><h3>Asatoma <em class="emph">Sad-Gamaya </em> | Tamaso Maa <em class="emph">Jyotir-Gamaya </em> | Mrytyor-Maa <em class="emph">Amritam Gamaya </em> | Om Shaantih Shaantih <em class="emph">Shaantihi </em></h3></div>

            </div>
            <div><Banner /></div>
        </frameElement>
    )
}


export default Header;



