import React from "react";
import './footer.css';
import './Media.css';

import logo from './img/khsp-logo.png';

function Footer() {
    return (
        <div className="footer-container">
            <div class="footer_body">
                <div className="footer_col1"><img src={logo} alt="Kerala Hindu Samajam - Perth" /></div>
                <div className="footer_col2"><h4>Kerala Hindu Samajam - Perth WA</h4></div>
                <div className="footer_col3"></div>
            </div>
        </div>
    )
}

export default Footer;