import React from "react";
import network from './img/ram.jpg';
import './Khsperth.css';


function Khsmission() {
    return (
        <div className="ausNetwork">
            <div className="ausNetwork_left"><h1>Our Mission</h1><p>Our mission is to provide Hindu religious knowledge through conducting classes, celebration of Hindu festivals, assist in performing Hindu rituals, and preserve the Indian traditions and the languages. In General our objective is to strengthen the common bonds of culture, tradition, social uniqueness and way of life of the Kerala Hindu community. </p></div>
            <div className="ausNetwork_right"><img src={network} className="at_image" alt="Kerala Hindu Samajam - Perth" /></div>
        </div>
    )
}

export default Khsmission;