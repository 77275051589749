import React from "react";
import image from './img/lord_siva.jpg';
import './Hinduism.css';


function Hinduism() {
    return (
        <div className="homePanel1">
            <div className="homePanel_left"><img src={image} className="at_image" alt="Kerala Hindu Samajam Perth" /></div>
            <div className="homePanel_right"><h1>Kerala Hindu Samajam</h1><p>Hinduism, now the third largest world religion, is a collective term applied to the many philosophical and religious traditions native to India. The word Hindu is derived from the Sanskrit word Sindhu, the Indian River, on the north western part of the Indian sub-continent. The sacred texts of Hinduism are the Vedas, Upanishads, Bhagavad Gita and the epics, Ramayana and Mahabharata. Hindus believe that the Spirit or Soul, the true “Self” of every person called the “atman” is eternal. Hinduism allows real freedom of choice in worship. It has developed numerous practices meant to help one think of divinity in the midst of everyday life. It is the duty of the elders to mould the young generation to be aware of the essence of Hinduism, its culture and ultimate goal to become a worthy human being in the society.
            </p></div>
        </div>
    )
}

export default Hinduism;