import React from "react";
import './header.css';
import './Home.css';
import './Media.css';
import map from './img/khsp-logo.png';

function Banner() {
  return (<div className="mainBanner"><h2>Kerala<br />Hindu Samajam</h2><h4>Perth WA</h4><img src={map} className="sliderimg" alt="Fibreglass Pools" /></div>
  )
}

export default Banner;