import { Autoplay, Pagination, A11y } from 'swiper/modules';
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import './Slider.css';
import './Media.css';

import Mar from './img/march30.png';
import Apr from './img/april27.png';
import May from './img/MAY11.png';
import Jun from './img/JUN15.png';
import Jul from './img/JUL13.png';
import Aug1 from './img/AUG03.png';
import Aug2 from './img/AUG17.png';
import Sep from './img/SEP07.png';
import Oct from './img/OCT05.png';
import Nov from './img/NOV09.png';
import Dec from './img/DEC07.png';

function Slider() {
  return (
    <Swiper
      modules={[Autoplay, Pagination, A11y]}
      spaceBetween={50}
      slidesPerView={1}
      autoplay={{
        "delay": 4500,
        "disableOnInteraction": false
      }}



      navigation
      /*pagination={{ clickable: false }}
      scrollbar={{ draggable: true }}*/




      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}



    >
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Mar} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Samajam Vishu</h5><p>Vishu is a Hindu festival celebrating the Malayali New Year in Kerala, Tulu Nadu, and Mahe of India. Vishu falls on the first day of the month of Medam in the Malayalam Calendar. It is the traditional new year</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Apr} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>International Women's Day + Vegetable Auction</h5><p>Join Kerala Hindu Samajam - Perth for International Women's Day / Vegetable Auction including delicious foods</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={May} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Kudumba Sangamam</h5><p>Join with Kerala Hindu Samajam - Perth for the Samaham family get together and fun activities</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Jun} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Kudumba Sangamam</h5><p>Join with Kerala Hindu Samajam - Perth for the Samaham family get together and fun activities</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Jul} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Dosa Night</h5><p>Join with Kerala Hindu Samajam - Perth for the Samaham Dosa Night and cultural activities</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Aug1} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Karkkidakavavu Bali</h5><p>Karkidaka Vavu, also known as 'Karkidaka Vavu Bali' or 'Vavu Bali' is a ritual performed by Hindus in Kerala to honour their dead ancestors</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Aug2} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Kudumba Sangamam</h5><p>Join with Kerala Hindu Samajam - Perth for the Samaham family get together and fun activities</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Sep} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Samajam Onam</h5><p>Onam is an annual harvest and cultural festival related to Hinduism that is celebrated mostly by the people of Kerala. A major annual event for Keralites</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Oct} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Vilakku Pooja</h5><p>Vilakku Pooja is symbolic of Mahalakshmi, the devi of fortune and prosperity. Mahalakshmi is worshiped collectively by a large number of women at a time by lighting lamps. It is said that it brings happiness and prosperity in the home and peace in the world</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Nov} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Family Fun Day + Sports carnival</h5><p>Join Kerala Hindu Samajam - Perth for a day of family fun and sports activities including delicious kerala non-veg foods</p></div></div></SwiperSlide>
      <SwiperSlide><div className='slide-steps'><div className='slide-step_r1'><img src={Dec} className='slide' alt="slider" /></div><div className='slide-step_r2'><h5>Ayyappa Padipooja</h5><p>Swami Saranam - We request all our members to join the Ayyappa Padi Pooja prayer we are celebrating on 2nd Dec at Balamurugan Temple Mandogalup</p></div></div></SwiperSlide>
      
    </Swiper>
  )
}

export default Slider;
